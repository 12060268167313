import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react'
import GTM from '@utils/GTM'
import './styles.scss'
import BottomCta from '@components/content/BottomCta'
import { navigate } from "gatsby"
import { isEDMode } from "@utils/helper"
import { useLocation } from "@reach/router";

const DdgForm = ({imageData}) => {
	const [ddgDone, setDdgDone] = useState(false);
	const [medicine, setMedicine] = useState(null);
	const [isAmbulatory, setIsAmbulatory] = useState(null);
    const ddgRef = useRef()

	const { search } = useLocation();

	const handleRadioButton = (e) => {
		if (e.keyCode === 13 || e.keyCode === 32) {
			e.target.querySelector('input').click();
		}
	}

	const handleInput = (e) => {
		if (e.target.name === 'ddg-form-q1') {
			setMedicine(e.target.value);
			ddgRef.current.querySelector('#ddg-form-q1').classList.remove('error-active');
		}

		if (e.target.name === 'ddg-form-q2') {
			setIsAmbulatory(e.target.value);
			ddgRef.current.querySelector('#ddg-form-q2').classList.remove('error-active');
		}
	}

	const submitDdg = () => {
		const matchesScenario = getQuery(medicine, isAmbulatory);

		if (matchesScenario) {
			setDdgDone(true)
			navigate(`?${matchesScenario}`, { state: { useScenario: true } })
			ddgRef.current.scrollIntoView({behavior: "smooth"})
			GTM.ddgFormComplete(medicine, isAmbulatory);

			ddgRef.current.querySelector('#ddg-form-q1').classList.remove('error-active');
			ddgRef.current.querySelector('#ddg-form-q2').classList.remove('error-active');
		} else {
			if (!medicine) ddgRef.current.querySelector('#ddg-form-q1').classList.add('error-active');
			if (!isAmbulatory) ddgRef.current.querySelector('#ddg-form-q2').classList.add('error-active');
		}
	}
	const resetDdg = () => {
		setDdgDone(false)
		setMedicine(null)
		setIsAmbulatory(null)
		navigate('/doctor-discussion-guide', { state: { useScenario: true } })
		ddgRef.current.scrollIntoView({behavior: "smooth"})
	}

	// return query string for a scenario if matches any scenario, else returns undefined
	const getQuery = (med, isAm) => {
		if (med === 'no-corticosteroid' && isAm === 'yes') {
			return 'scenario_1'
		} else if (med === 'prednisone' && isAm === 'yes') {
			return 'scenario_2'
		} else if ((med === 'prednisone' && isAm === 'no') || (med === 'no-corticosteroid' && isAm === 'no')) {
			return 'scenario_3'
		} else if ( med === 'emflaza' && isAm === 'yes' ) {
			return 'scenario_4'
		} else if (med === 'emflaza' && isAm === 'no' ) {
			return 'scenario_5'
		}
	}

	// return true if question/bottomCTA matches scenario
	const isForCurrentScenario = (scenario_med, scenario_isAmp) => medicine === scenario_med && isAmbulatory === scenario_isAmp;

	// handle form state when navigating using go back/go forward arrow button in the browser
	useEffect(() => {
		if (!search && ddgDone) {
			resetDdg()
		}
	}, [search])

	useEffect(() => {
		if (isEDMode()) {
			if (search.includes('ddg_selected')) {
				ddgRef.current.querySelectorAll('#ddg-form-q1 div:nth-child(1) label input, #ddg-form-q2 div:nth-child(1) label input').forEach(el=>el.click())
			}

			if (search.includes('ddg_errors')) {
				ddgRef.current.querySelector('#ddg-form-q1').classList.add('error-active');
				ddgRef.current.querySelector('#ddg-form-q2').classList.add('error-active');
			}
		}

		// show scenario for ED and for if url contains it (for saved links)
		if (search.includes('scenario')) {
			setDdgDone(true)
		}

		if (search.includes('scenario_1')) {
			setMedicine('no-corticosteroid')
			setIsAmbulatory('yes')
		} else if (search.includes('scenario_2')) {
			setMedicine('prednisone')
			setIsAmbulatory('yes')
		} else if (search.includes('scenario_3')) {
			setMedicine('prednisone')
			setIsAmbulatory('no')
		} else if (search.includes('scenario_4')) {
			setMedicine('emflaza')
			setIsAmbulatory('yes')
		} else if (search.includes('scenario_5')) {
			setMedicine('emflaza')
			setIsAmbulatory('no')
		}
	},[])

	const formQ1 = [
		{
			copy: 'EMFLAZA',
			value: 'emflaza',
		},
		{
			copy: 'Prednisone',
			value: 'prednisone',
		},
		{
			copy: 'No Corticosteroid',
			value: 'no-corticosteroid',
		},
	]

	const formQ2 = [
		{
			copy: 'Yes',
			value: 'yes',
		},
		{
			copy: 'No',
			value: 'no',
		},
	]

	// The questions Array contains all the questions and renders the ones in order(1,2,3) based on scenario.
	const questions = [
		{
			title: `Will my child require a corticosteroid? If so, how will we determine the right time?`,
			copy: `Corticosteroids are often introduced soon after DMD diagnosis to ease inflammation and preserve muscle strength. It's important to discuss with your son's healthcare provider if and when corticosteroids are right for your child.`,
			isPresent: isForCurrentScenario('no-corticosteroid', 'yes'),
			order: 1,
		},
		{
			title: `Does it matter which corticosteroid we choose?`,
			copy: `Every boy is different, so there are options when choosing a corticosteroid. You can work with your care team to understand your options, their indications, benefits, side effects, and how they can affect your son differently.`,
			link: {
				url: '/understanding-corticosteroids',
				copy: 'Learn About Corticosteroids',
			},
			isPresent: isForCurrentScenario('no-corticosteroid', 'yes'),
			order: 2,
		},
		{
			title: `How long will my son need to be on a corticosteroid?`,
			isPresent: isForCurrentScenario('no-corticosteroid', 'yes'),
			order: 3,
		},
		{
			title: `Why should we start treatment if my son is still walking?`,
			isPresent: isForCurrentScenario('no-corticosteroid', 'yes'),
			order: 4,
		},
		{
			title: `What can we expect, both short-term and long-term, from our son being on a corticosteroid?`,
			isPresent: isForCurrentScenario('no-corticosteroid', 'yes'),
			order: 5,
		},
		{
			title: `Does it matter which corticosteroid we choose?`,
			copy: `Every boy is different, so there are options when choosing a corticosteroid. You can work with your care team to understand your options, their indications, benefits, side effects, and how they can affect your son differently.`,
			isPresent: isForCurrentScenario('prednisone', 'yes'),
			order: 1,
		},
		{
			title: `Should we be concerned about weight gain? Are there options or steps we can take to help?`,
			isPresent: isForCurrentScenario('prednisone', 'yes') || isForCurrentScenario('prednisone', 'no') || isForCurrentScenario('no-corticosteroid', 'no'),
			order: isForCurrentScenario('prednisone', 'yes') ? 2 : 3,
		},
		{
			title: `Are there things we can do if we start noticing emotional or behavioral changes?`,
			isPresent: isForCurrentScenario('prednisone', 'yes'),
			order: 3,
		},
		{
			title: `What are our options and/or next steps if we start to see his strength or mobility decline while on treatment?`,
			isPresent: isForCurrentScenario('prednisone', 'yes'),
			order: 4,
		},
		{
			title: `When does it make sense to switch from one corticosteroid to another?`,
			isPresent: isForCurrentScenario('prednisone', 'yes'),
			order: 5,
		},
		{
			title: `Is there another corticosteroid we should consider?`,
			copy: `Every boy is different, so there are options when choosing a corticosteroid. You can work with your care team to understand your options, their indications, benefits, side effects, and how they can affect your son differently.`,
			isPresent: isForCurrentScenario('prednisone', 'no') || isForCurrentScenario('no-corticosteroid', 'no'),
			order: 1,
		},
		{
			title: `Aside from keeping him strong, what do corticosteroids actually do?`,
			isPresent: isForCurrentScenario('prednisone', 'no') || isForCurrentScenario('no-corticosteroid', 'no'),
			order: 2,
		},
		{
			title: `Are there things we can do if we start noticing emotional or behavioral changes?`,
			isPresent: isForCurrentScenario('prednisone', 'no') || isForCurrentScenario('no-corticosteroid', 'no'),
			order: 4,
		},
		{
			title: `What are our options and/or next steps if we start to see his strength or mobility decline while on treatment?`,
			isPresent: isForCurrentScenario('prednisone', 'no') || isForCurrentScenario('no-corticosteroid', 'no'),
			order: 5,
		},
		{
			title: `My son is in a wheelchair. Why keep him on treatment at this point?`,
			copy: `Corticosteroids can help different parts of the body over the long-term. Your son's healthcare provider can share all the benefits of remaining on treatment. In the meantime, you can learn how EMFLAZA has helped other patients over time.`,
			isPresent: isForCurrentScenario('prednisone', 'no') || isForCurrentScenario('no-corticosteroid', 'no'),
			order: 6,
			link: {
				url: '/about-emflaza#long-term-data',
				copy: 'See Long-Term Data',
			},
		},
		{
			title: `How can EMFLAZA continue to help my son as his disease progresses?`,
			isPresent: isForCurrentScenario('emflaza', 'yes'),
			order: 1,
		},
		{
			title: `When should we consider a dosing change?`,
			copy: `Your son's healthcare provider will know when it's time to update the dose. EMFLAZA dosing is based on bodyweight. A simple rule to remember is the dosage should change anytime your son's weight changes by 6 or more kilograms (over 13 pounds).`,
			isPresent: isForCurrentScenario('emflaza', 'yes') || isForCurrentScenario('emflaza', 'no'),
			link: {
				url: '/dosing',
				copy: 'Learn More About Dosing',
			},
			order: 2,
		},
		{
			title: `Does EMFLAZA have any impact on the need for scoliosis surgery?`,
			isPresent: isForCurrentScenario('emflaza', 'yes') || isForCurrentScenario('emflaza', 'no'),
			order: 3,
		},
		{
			title: `Does EMFLAZA have any effect on preserving lung function?`,
			isPresent: isForCurrentScenario('emflaza', 'yes') || isForCurrentScenario('emflaza', 'no'),
			order: 4,
		},
		{
			title: `When it comes to participating in clinical trials, can my child be on EMFLAZA?`,
			copy: `Many clinical trials require patients to be on a corticosteroid to participate. You should check with the clinicians running the study, but your son may be able to join if he's on EMFLAZA depending on the criteria. Learn about the EMFLAZA clinical trials.`,
			isPresent: isForCurrentScenario('emflaza', 'yes'),
			link: {
				url: '/about-emflaza',
				copy: 'See EMFLAZA Clinical Data',
			},
			order: 5,
		},
		{
			title: `My son is in a wheelchair. What are some reasons for him to stay on EMFLAZA?`,
			isPresent: isForCurrentScenario('emflaza', 'no'),
			order: 1,
		},
		{
			title: `Could my son take EMFLAZA if he's joining a clinical trial?`,
			copy: `Many clinical trials require patients to be on a corticosteroid to participate. You should check with the clinicians running the study, but your son may be able to join if he's on EMFLAZA depending on the criteria. Learn about the EMFLAZA clinical trials.`,
			isPresent: isForCurrentScenario('emflaza', 'no'),
			link: {
				url: '/about-emflaza',
				copy: 'See EMFLAZA Clinical Data',
			},
			order: 5,
		},
	]

	const bottomCta = [
		{
			copy: <span className='whitespace-nowrap'>Understand<br className='inline md:hidden'/> the stages of<br className='inline md:hidden'/> dmd</span>,
			url: '/understanding-corticosteroids#caring-for-dmd',
			image: 'ddg-bottom-cta-1.png',
			isPresent: isForCurrentScenario('no-corticosteroid', 'yes'),
			imageObj: imageData.iconImage1,
		},
		{
			copy: 'Understand corticosteroids',
			url: '/understanding-corticosteroids',
			image: 'ddg-bottom-cta-1.png',
			isPresent: isForCurrentScenario('prednisone', 'yes'),
			imageObj: imageData.iconImage1,
		},
		{
			copy: <span className='whitespace-nowrap'>Hear how<br className='inline md:hidden'/> one family<br className='inline md:hidden'/> manages dmd</span>,
			url: '/about-emflaza#kasners',
			image: 'ddg-bottom-cta-2.png',
			isPresent: isForCurrentScenario('prednisone', 'no') || isForCurrentScenario('no-corticosteroid', 'no'),
			imageObj: imageData.iconImage2,
		},
		{
			copy: 'See long-term data',
			url: '/about-emflaza#long-term-data',
			image: 'ddg-bottom-cta-3.png',
			isPresent: isForCurrentScenario('emflaza', 'yes'),
			imageObj: imageData.iconImage3,
		},
		{
			copy: 'Learn how emflaza helps',
			url: '/about-emflaza',
			image: 'ddg-bottom-cta-2.png',
			isPresent: isForCurrentScenario('emflaza', 'no'),
			imageObj: imageData.iconImage2
		},
	]

    return(
		<div ref={ddgRef}>
			{/* Before Submitted */}
			{!ddgDone &&
				<div className='ddg-form ddg-form--start'>
					<h2>Answer the two questions below to get a discussion guide for your next visit.</h2>
					<div className='ddg-form--question-1'>
						<h3>Which corticosteroid is your child currently taking?</h3>
						<div id='ddg-form-q1'>
							{formQ1.map((el,i)=> {
								return(
									<div key={`formQ1-${i}-${el.value}`} className="radio-input" tabIndex="0" onKeyDown={handleRadioButton}>
										<label>
											<input type="radio" name="ddg-form-q1" tabIndex="-1" className="sr-only" value={el.value} onChange={handleInput} />
											<span className="radio-bg">
												<span className="radio-content"></span>
											</span>
											<span className="radio-copy">
												<span>{el.copy}</span>
											</span>
										</label>
									</div>
								)
							})}
						<div className='radio-error-msg'>Please make a selection</div>
						</div>
					</div>

					<div className='ddg-form--question-2'>
						<h3>Is your son still ambulatory (walking without a wheelchair)?</h3>
						<div id='ddg-form-q2'>
							{formQ2.map((el,i)=> {
								return(
									<div key={`formQ2-${i}-${el.value}`} className="radio-input" tabIndex="0" onKeyDown={handleRadioButton}>
										<label>
											<input type="radio" name="ddg-form-q2" tabIndex="-1" className="sr-only" value={el.value} onChange={handleInput} />
											<span className="radio-bg">
												<span className="radio-content"></span>
											</span>
											<span className="radio-copy">
												<span>{el.copy}</span>
											</span>
										</label>
									</div>
								)
							})}
						<div className='radio-error-msg'>Please make a selection</div>
						</div>
					</div>

					<button type='button' className='bg-emflaza-blue-600 border border-emflaza-blue-600 py-3 px-28 text-emflaza-white-100 font-bold hover:bg-emflaza-blue-25 focus:bg-emflaza-blue-25 hover:text-emflaza-blue-600 focus:text-emflaza-blue-600' onClick={submitDdg}>
						Submit
					</button>
				</div>
			}

			{/* After Submitted */}
			{ddgDone &&
				<>
					<div className='ddg-form ddg-form--completed'>
						<h2>Thank you for your responses. Below you will find some key questions to keep in mind at your son's next visit to the doctor. Your child's needs will change throughout the course of their journey, so be sure to return here for support on future conversations with your son's healthcare provider.</h2>

						<ol>
							{questions
							.filter(el => el.isPresent)
							.sort((q1, q2) => q1.order - q2.order)
							.map((el,i) => {
								return (
									<li key={`questions-answered-${i}`}>
										<span className='ddg-list-title'>{el.order}. {el.title}</span>
										{el.copy && <span className='ddg-list-copy'>{el.copy}</span>}
										{ el.link && <a href={el.link.url} target='_blank' rel="noreferrer">{el.link.copy}</a>}
									</li>
								)
							})}
						</ol>

						<div className='h-px bg-emflaza-blue-600 w-full'></div>

						<div className='ddg-form--completed-bottom-btns'>
							<button onClick={ () => navigator.clipboard.writeText(window.location.href)}>Copy Link</button>
							<button onClick={resetDdg}>Reset Form</button>
						</div>
					</div>

					{bottomCta
						.filter(el=> el.isPresent)
						.map((el,i)=>{
							return(
								<BottomCta
									key={`bottomCta-${i}`}
									ctaUrl={el.url}
									image={{
										leftImage: imageData.leftImage,
										leftImageMobile: imageData.leftImageMobile,
										iconImage: el.imageObj,
										rightImage: imageData.rightImage,
										rightImageMobile: imageData.rightImageMobile,
									}}
									hasAnchor
									target='_blank'
								>
									{el.copy}
								</BottomCta>
							)
						})
					}
				</>
			}
		</div>
    );
};

export default DdgForm;

DdgForm.propTypes = {
    imageData: PropTypes.object.isRequired,
}
