import React, { useEffect, useContext } from "react";
import { KfHeroUtility } from "@klickinc/kf-react-components";
import AppContext from "@src/context";
// Utility imports
import Seo from "@components/utility/SEO";
import { graphql } from 'gatsby';

import "./styles.scss";

// Content imports
import DdgForm from "@components/content/ddg-form/DdgForm";

const Ddg = ({ data }) => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		if (typeof window !== "undefined") {
			ctx.setPageClass("page--ddg");
		}
	}, []);

	return (
		<>
			<section className="relative">
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading heading-hero-blue xl:pt-14">
						Helpful discussion topics for you and your care team
					</KfHeroUtility>

					<div className="max-w-xl">
						<p>
							Navigating conversations with your son's healthcare
							provider can be overwhelming. Bringing a discussion
							guide may help you have a productive dialogue at
							your next appointment.
						</p>
					</div>
				</div>
			</section>

			<section className="relative">
				<div className="container container--inner">
					<DdgForm imageData={data} />
				</div>
			</section>

			<div className="bg--light-orange"></div>
		</>
	);
};

export default Ddg;


export const pageQuery = graphql`
  query {
	leftImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImageMobile: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImageMobile: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-3-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage1: file(relativePath: {eq: "pages/doctor-discussion-guide/ddg-bottom-cta-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage2: file(relativePath: {eq: "pages/doctor-discussion-guide/ddg-bottom-cta-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage3: file(relativePath: {eq: "pages/doctor-discussion-guide/ddg-bottom-cta-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo
			title="Doctor Discussion Guide - EMFLAZA® (deflazacort)"
			description="Use this discussion guide to help in having a productive dialogue at your son's next appointment. See full Prescribing Information & Important Safety Information."
			addSocialMeta={false}
		/>
	);
};
